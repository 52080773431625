/* styles for clock component  */

.clock {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #333;
    color:aquamarine
}

h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: lowercase;
    font-size: 3rem;
    margin: 0;
}

@media (min-width: 768px) {

    h1 {
        font-size: 5rem;
    }
  
}